@import "~swiper/css/bundle";

:root {
    --swiper-pagination-color: #4f46e5;
    --swiper-theme-color: #7e75fa;
    --swiper-navigation-size: 24px
}

.parallax-bg {
    @apply absolute inset-0 h-full;
    width: 130%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
}
