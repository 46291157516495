@import "~toastify-js/src/toastify.css";

.toastify {
    @apply z-[10010] rounded bg-none font-medium shadow-none bg-slate-200 text-slate-800 dark:text-navy-50 dark:bg-navy-600
}

.toastify.html{
    @apply p-0 cursor-auto bg-transparent shadow-none border-none
}

.toastify.primary{
    @apply bg-primary dark:bg-accent text-white
}

.toastify.secondary{
    @apply bg-secondary text-white
}

.toastify.info{
    @apply bg-info text-white
}

.toastify.warning{
    @apply bg-warning text-white
}

.toastify.success{
    @apply bg-success text-white
}

.toastify.error{
    @apply bg-error text-white
}

.toastify.light{
    @apply bg-slate-200 text-navy-800
}

.toastify.dark{
    @apply bg-navy-600 text-slate-200
}