.steps {
  @apply flex items-baseline [--size:2rem] [--line:0.25rem];
}

.steps.line-space {
  --space: 0.5rem;
}

.steps .step {
  @apply flex flex-1 flex-col break-words text-center font-inter tracking-wide;
}

.steps .step .step-header {
  @apply relative m-auto mb-2 flex h-[var(--size)] w-[var(--size)] shrink-0 items-center justify-center;
}

.steps .step:not(:last-child)::before {
  @apply relative left-[50%] top-[calc(var(--size)/2+var(--line)/2)] order-[-1] h-[var(--line)] rounded-full content-[''];
}

.steps.line-space .step:not(:last-child)::before {
  width: calc(100% - var(--size) - calc(var(--space) * 2));
  left: calc(50% + calc(var(--size) / 2 + var(--space)));
}

.steps.is-vertical {
  @apply flex-col;
}

.steps.is-vertical .step {
  @apply relative flex-row;
}

.steps.is-vertical .step .step-header {
  @apply z-1 m-0;
}

.steps.is-vertical .step:not(:last-child)::before {
  @apply absolute inset-0 h-auto w-[var(--line)] translate-x-[calc((var(--size)/2)-(var(--line)/2))];
}

.steps.is-vertical.line-space .step:not(:last-child)::before {
  @apply top-[calc(var(--size)+var(--space))] bottom-[var(--space)];
}
