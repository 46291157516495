/* notifications.css */

.notification-popup {
  position: fixed;
  top: 0;
  right: 0;
  margin: 0;
  transform: translate3d(-58.4px, 58.4px, 0px);
  animation: slide-in 0.25s ease-out forwards;
}

.notification-popup.slide-out {
  animation: slide-out 0.25s ease-out forwards;
}

.hide {
  display: none;
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translate3d(-58.4px, 30%, 0px);
  }
  to {
    opacity: 1;
    transform: translate3d(-58.4px, 58.4px, 0px);
  }
}

@keyframes slide-out {
  from {
    opacity: 1;
    transform: translate3d(-58.4px, 58.4px, 0px);
  }
  to {
    opacity: 0;
    transform: translate3d(-58.4px, 30%, 0px);
  }
}

@media (max-width: 640px) {
  .notification-popup {
    top: 25%;
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%);
    animation: slide-in-center 0.25s ease-out forwards;
  }

  .notification-popup.slide-out {
    animation: slide-out-center 0.25s ease-out forwards;
  }

  @keyframes slide-in-center {
    from {
      opacity: 0;
      transform: translate(-50%, -60%);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  @keyframes slide-out-center {
    from {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
    to {
      opacity: 0;
      transform: translate(-50%, -60%);
    }
  }
}
